export const  API_ENDPOINT = "https://api.spectransyscore.com/api/";
export const  API_ENDPOINT_IMG = "https://api.spectransyscore.com";
// export const API_ENDPOINT = "http://192.168.29.96:8081/api/";
// export const API_ENDPOINT_IMG = "http://192.168.29.96:8081";
// export const API_ENDPOINT = "http://192.168.0.117:8081/api/";

// keyId: 'rzp_test_49pMyOx78pcs53',
// KeySecret: 'pjeMjZLNyvjWfqKFIUyUp8st',

export const keyId = "rzp_test_49pMyOx78pcs53";
export const KeySecret = "pjeMjZLNyvjWfqKFIUyUp8st";


export const API_TOKEN = "hns@gaDv8j1mBhbsk26bDpal7H8jDvn";
export const capitalizeText = (text) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    // Handle the case when text is undefined or null
    return "";
  }
};
export const validateLinkedInURL = (url) => {
  const linkedInPattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/[a-zA-Z]{2,3}\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]*)?(\/?)(\?[^ ]+)?(\/?)$/;
  return linkedInPattern.test(url);
};
