"use client";
import React from "react";
import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
} from "@headlessui/react";
import { IoClose } from "react-icons/io5";

const RenderHTML = ({ htmlContent }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default function BioData({ open, setOpen, data, eventName }) {
 
 
  const sanitizeDescription = (description) => {
    return description?.replace(/(<p><br><\/p>)+/g, '<p><br></p>');
  }
  const sanitizedDescription = sanitizeDescription(data?.description);


  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="relative z-30"
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen h-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-[80%] sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {/* <h3 className="font-bold text-center text-xl text-black block pb-5">
              Bio Data
            </h3> */}

            <CloseButton
              className={"absolute right-5 top-5 text-lg text-red-600"}
            >
              <IoClose />
            </CloseButton>
            <div
              className="text-center global-title my-5"
              style={{ fontFamily: "custom" }}
            >
              <h2 className="inline-block font-extrabold text-lg text-black cursor-pointor">
                {eventName}
              </h2>
            </div>
            <p className="text-[#808080] text-sm mb-3 leading-6 max-h-96 overflow-auto aScrollBar">
              <RenderHTML htmlContent={data ? sanitizedDescription : ""} />
            </p>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
