import React, { useState, useCallback, useEffect } from "react";
import { PrevButton, NextButton, usePrevNextButtons } from "../ArrowButtons";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import BioData from "../Modals/BioModal/AttendeeBio";
import PanelData from "../Modals/BioModal/PanelData";
import { axiosGet } from "../../lib/api";
import ModeratorBio from "../Modals/BioModal/Moderator";
// import '../embla.css'

const EmblaCarousel = () => {
  const [bioType, setBioType] = useState(1);
  const [openBio, setOpenBio] = useState(false);
  const [openPanel, setOpenPanel] = useState(false);
  const [openModerator, setOpenModerator] = useState(false);
  const [selectedSpeaker, setSelectedSpeaker] = useState([]);
  const [selectedPanelist, setSelectedPanelist] = useState([]);
  const [selectedModerator, setSelectedModerator] = useState([]);
  const options = { dragFree: true, loop: true, slidesToScroll: "auto" };
  // loop: true, slidesToScroll: "auto"
  //   options, [Autoplay()]
  const [emblaRef1, emblaApi1] = useEmblaCarousel(options, [Autoplay()]);
  const [emblaRef2, emblaApi2] = useEmblaCarousel(options, [Autoplay()]);
  const [emblaRef3, emblaApi3] = useEmblaCarousel(options, [Autoplay()]);

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;
    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;
    resetOrStop();
  }, []);

  const {
    prevBtnDisabled: prevBtnDisabled1,
    nextBtnDisabled: nextBtnDisabled1,
    onPrevButtonClick: onPrevButtonClick1,
    onNextButtonClick: onNextButtonClick1,
  } = usePrevNextButtons(emblaApi1, onNavButtonClick);

  const {
    prevBtnDisabled: prevBtnDisabled2,
    nextBtnDisabled: nextBtnDisabled2,
    onPrevButtonClick: onPrevButtonClick2,
    onNextButtonClick: onNextButtonClick2,
  } = usePrevNextButtons(emblaApi2, onNavButtonClick);
  const {
    prevBtnDisabled: prevBtnDisabled3,
    nextBtnDisabled: nextBtnDisabled3,
    onPrevButtonClick: onPrevButtonClick3,
    onNextButtonClick: onNextButtonClick3,
  } = usePrevNextButtons(emblaApi3, onNavButtonClick);

  const [speakerData, setSpeakerData] = useState([]);
  const [panelistData, setPanelistData] = useState([]);
  const [moderatorData, setModeratorData] = useState([]);

  const fetchSpeaker = async () => {
    axiosGet
      .get(`speaker/get/website?status=${1}`)
      .then((response) => {
        setSpeakerData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchPanelist = async () => {
    axiosGet
      .get(`panelists/get/web`)
      .then((response) => {
        setPanelistData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchModerators = async () => {
    axiosGet
      .get(`moderators/get/web`)
      .then((response) => {
        setModeratorData(response.data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchModerators();
    fetchPanelist();
    fetchSpeaker();
  }, []);

  const viewSpeaker = (item, type) => {
    setBioType(type);
    setOpenBio(true);
    setSelectedSpeaker(item);
  };
  const viewPanelist = (item) => {
    setOpenPanel(true);
    setSelectedPanelist(item);
  };
  const viewModerators = (item) => {
    setOpenModerator(true);
    setSelectedModerator(item);
  };

  return (
    <>
      <div className=" py-4  max-w-7xl mx-auto px-8 lg:px-0" id="speakers">
        <div
          className="text-center global-title my-5"
          style={{ fontFamily: "custom" }}
        >
          <h2 className="inline-block font-extrabold text-xl md:text-3xl text-black">
            Speakers
          </h2>
        </div>
        {speakerData.length > 0 ? (
          <section className="embla relative">
            {speakerData.length > 4 && (
              <div className="embla__controls" style={{ marginTop: "0" }}>
                <div
                  className="embla__buttons flex justify-between w-full absolute left-0 right-0 top-1/2 transform -translate-y-1/2 "
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="relative w-full flex justify-between">
                    <div className="bg-transparent rounded-lg absolute -left-10">
                      <PrevButton
                        onClick={onPrevButtonClick1}
                        disabled={prevBtnDisabled1}
                      />
                    </div>
                    <div className="bg-transparent rounded-lg absolute -right-10">
                      <NextButton
                        onClick={onNextButtonClick1}
                        disabled={nextBtnDisabled1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="embla__viewport pb-8" ref={emblaRef1}>
              <div className="embla__container">
                {speakerData?.map((item, index) => (
                  <div
                    className="embla__slide cursor-pointer"
                    key={index}
                    onClick={() => viewSpeaker(item, 1)}
                  >
                    <div className="embla__slide__number h-full py-4 min-w-full">
                      <div className="bg-white h-full relative shadow-lg hover:shadow-xl transition duration-300 rounded-lg mx-2 md:mx-auto cursor-pointer group min-w-full">
                        <img
                          className="rounded-t-lg w-full h-40 bg-white"
                          src={
                            item.coverPhoto
                              ? item.coverPhoto
                              : "/images/speaker_default.jpg"
                          }
                          alt=""
                        />
                        <div className="py-3 px-4 rounded-lg bg-white relative min-h-24">
                          <img
                            // className="rounded-full w-28 aspect-square absolute -inset-y-14 left-1 right-auto border-white border-2 shadow-lg "
                            className="rounded-full w-24 aspect-square absolute -inset-y-14 left-1/2 transform -translate-x-1/2 border-white border-2 shadow-lg"
                            // style={{ transform: "translate(50%,0)" }}
                            src={item.photo ? item.photo : "/images/1.jpg"}
                            // src={item.photo}
                            alt=""
                          />
                          <div className="text-center mt-10">
                            <>
                              <p className="text-base group-hover:text-[#ed3336] duration-300 font-semibold capitalize line-clamp-1 ">
                                {item.firstName} {item.lastName}
                              </p>
                              <p className="text-sm text-slate-600 line-clamp-2 ">
                                {item.company ? item.company : "---"}
                              </p>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          <>
            {/* <div className="text-center py-3">No speakers right now...</div> */}
            <div className="embla__slide cursor-pointer">
              <div className="embla__slide__number h-full py-4 min-w-full">
                <div className="bg-white h-full relative shadow-lg rounded-lg mx-2 md:mx-auto cursor-pointer group min-w-full">
                  <div className="py-3 px-4 rounded-lg bg-white relative min-h-24 flex justify-center items-center">
                    <p className="text-xl text-gray-500 text-center">
                      Speakers will be updated soon!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="bg-[#fff4eb] py-4 mx-auto px-8 md:px-0" id="panelist">
        <div
          className="text-center global-title my-5"
          style={{ fontFamily: "custom" }}
        >
          <h2 className="inline-block font-extrabold text-xl md:text-3xl text-black">
            Panelists
          </h2>
        </div>
        <div className=" max-w-7xl mx-auto">
          {panelistData.length > 0 ? (
            <section className="embla relative">
              {panelistData.length > 4 && (
                <div className="embla__controls" style={{ marginTop: "0" }}>
                  <div
                    className="embla__buttons flex justify-between w-full absolute left-0 right-0 top-1/2 transform -translate-y-1/2 "
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="relative w-full flex justify-between">
                      <div className="bg-transparent rounded-lg absolute -left-10">
                        <PrevButton
                          onClick={onPrevButtonClick2}
                          disabled={prevBtnDisabled2}
                        />
                      </div>
                      <div className="bg-transparent rounded-lg absolute -right-10">
                        <NextButton
                          onClick={onNextButtonClick2}
                          disabled={nextBtnDisabled2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="embla__viewport pb-8 px-6 " ref={emblaRef2}>
                <div className="embla__container">
                  {" "}
                  {panelistData.map((item, index) => (
                    <div
                      className="embla__slide cursor-pointer"
                      onClick={() => viewPanelist(item)}
                      key={index}
                    >
                      <div className="bg-white shadow-lg rounded-lg overflow-hidden my-4 w-full">
                        <img
                          className="w-full h-44 object-fill object-center"
                          // src={item.photo}
                          src={item.photo ? item.photo : "/images/1.jpg"}
                          alt="avatar"
                        />
                        <div className="py-4 pt-3 px-6 h-24">
                          <div className="text-center mt-2">
                            <>
                              <p className="text-lg group-hover:text-[#ed3336] duration-300 font-semibold capitalize line-clamp-1 ">
                                {item.firstName} {item.lastName}
                              </p>
                              <p className="capitalize text-[#808080] text-sm line-clamp-2">
                                {item.jobTitle}, {item.organization}
                              </p>
                            </>
                          </div>
                        </div>
                      </div>
                      {/* <div className="bg-white w-full text-center rounded-3xl shadow-xl p-4 h-24">
                        <h3 className="font-semibold capitalize line-clamp-1">
                          {" "}
                          {item.firstName} {item.lastName}
                        </h3>
                        <p className="capitalize text-[#808080] text-sm line-clamp-2">
                          {item.jobTitle}, {item.organization}
                        </p>
                      </div>
                      <div className="panalist-service-img">
                        <div className="mx-auto">
                          <img src={item.photo} alt="img" />
                        </div>
                      </div> */}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <div className="embla__slide cursor-pointer">
              <div className="embla__slide__number h-full py-4 min-w-full">
                <div className="bg-white h-full relative shadow-lg rounded-lg mx-2 md:mx-auto cursor-pointer group min-w-full">
                  <div className="py-3 px-4 rounded-lg bg-white relative min-h-24 flex justify-center items-center">
                    <p className="text-xl text-gray-500 text-center">
                      Panelists will be updated soon!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="py-4 mx-auto px-8 md:px-0" id="moderators">
        <div
          className="text-center global-title my-5"
          style={{ fontFamily: "custom" }}
        >
          <h2 className="inline-block font-extrabold text-xl md:text-3xl text-black">
            Moderators
          </h2>
        </div>
        <div className=" max-w-7xl mx-auto">
          {moderatorData.length > 0 ? (
            <section className="embla relative">
              {moderatorData.length > 4 && (
                <div className="embla__controls" style={{ marginTop: "0" }}>
                  <div
                    className="embla__buttons flex justify-between w-full absolute left-0 right-0 top-1/2 transform -translate-y-1/2 "
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="relative w-full flex justify-between">
                      <div className="bg-transparent rounded-lg absolute -left-10">
                        <PrevButton
                          onClick={onPrevButtonClick3}
                          disabled={prevBtnDisabled3}
                        />
                      </div>
                      <div className="bg-transparent rounded-lg absolute -right-10">
                        <NextButton
                          onClick={onNextButtonClick3}
                          disabled={nextBtnDisabled3}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="embla__viewport pb-8 p-3" ref={emblaRef3}>
                <div className="embla__container ">
                  {moderatorData?.map((item, index) => (
                    <div
                      className="embla__slide cursor-pointer"
                      key={index}
                      onClick={() => viewModerators(item)}
                    >
                      <div className="bg-white shadow-lg rounded-lg overflow-hidden my-4 w-full">
                        <img
                          className="w-full h-44 object-cover object-center"
                          // src={item.photo}
                          src={item.photo ? item.photo : "/images/1.jpg"}
                          alt="avatar"
                        />
                        <div className="py-4 pt-3 px-6 h-24">
                          <div className="text-center mt-2">
                            <>
                              <p className="text-lg group-hover:text-[#ed3336] duration-300 font-semibold capitalize line-clamp-1 ">
                                {item.firstName} {item.lastName}
                              </p>
                              <p className="capitalize text-[#808080] text-sm line-clamp-2">
                                {item.jobTitle}, {item.organization}
                              </p>
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
          ) : (
            <div className="embla__slide cursor-pointer">
              <div className="embla__slide__number h-full py-4 min-w-full">
                <div className="bg-white h-full relative shadow-lg rounded-lg mx-2 md:mx-auto cursor-pointer group min-w-full">
                  <div className="py-3 px-4 rounded-lg bg-white relative min-h-24 flex justify-center items-center">
                    <p className="text-xl text-gray-500 text-center">
                      Moderators will be updated soon!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <BioData
        open={openBio}
        setOpen={setOpenBio}
        type={bioType}
        data={selectedSpeaker}
      />
      <PanelData
        open={openPanel}
        setOpen={setOpenPanel}
        data={selectedPanelist}
      />
      <ModeratorBio
        open={openModerator}
        setOpen={setOpenModerator}
        data={selectedModerator}
      />
    </>
  );
};

export default EmblaCarousel;
