// components/FileUpload.js
import React, { useState } from "react";
import { CgClose } from "react-icons/cg";

const FileUpload = ({
  file,
  setFile,
  fileUrl,
  setFileUrl,
  fileName,
  setFileName,
  label,
  fileType,
}) => {
  const [error, setError] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 70 * 1024 * 1024) {
        // 2MB in bytes
        setError(`File size exceeds 50MB. Please upload a smaller file.`);
        setFile(null);
        setFileUrl(null);
        setFileName("");
      } else {
        setError(" ");
        setFile(selectedFile);
        setFileName(selectedFile.name);
        setFileUrl(URL.createObjectURL(selectedFile));
      }
    }
  };

  const resetFile = () => {
    setFile(null);
    setFileUrl(null);
    setFileName("");
    setError("");
  };

  return (
    <div className="relative col-span-4">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="mt-1 flex justify-between items-center gap-2 rounded-lg border border-dashed border-gray-300 py-1 px-3">
        <div className="flex text-sm leading-6 text-gray-400 w-full">
          <label
            htmlFor={`${fileType}-upload`}
            className="cursor-pointer rounded w-full text-black h-full"
          >
            <p className="max-w-52 truncate">{fileName || `Upload ${label}`}</p>
            <input
              id={`${fileType}-upload`}
              name={`${fileType}-upload`}
              type="file"
              accept=".doc,.docx,.pdf"
              className="sr-only"
              onChange={handleFileChange}
            />
          </label>
        </div>
        {fileName && (
          <div className="text-sm text-gray-400 flex items-center gap-2">
            <button
              type="button"
              onClick={resetFile}
              aria-label={`Remove ${label}`}
            >
              <CgClose />
            </button>
            <a
              href={fileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-indigo-500 hover:underline"
            >
              View
            </a>
          </div>
        )}
      </div>
      {/* {error && <p className="mt-0.5 text-xs text-red-600">{error}</p>} */}
      {error ? (
        <p className="text-red-500 text-xs text-center pb-2">{error}</p>
      ) : (
        <p className="text-gray-400 text-xs pb-2">
          Maximum size is 50MB.
        </p>
      )}
    </div>
  );
};

export default FileUpload;
