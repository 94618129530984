"use client";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { axiosGet, axiosPostForm } from "../../../lib/api";
import React, { useState, useEffect, useRef } from "react";
import { MdClose } from "react-icons/md";
import FileUpload from "../../FileUpload";
import { CgClose } from "react-icons/cg";
import Toast from "../../Toast";
import { PlusIcon } from "@heroicons/react/16/solid";
import { validateLinkedInURL } from "../../../lib/config";

export default function Speaker({ open, setOpen }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [intro, setIntro] = useState("");
  const [picture, setPicture] = useState(null);
  const [pictureCv, setPictureCv] = useState(null);
  const fileInputRef = useRef(null);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pictureUrl, setPictureUrl] = useState(null);
  const [pictureName, setPictureName] = useState("");
  const [pictureCvUrl, setPictureCvUrl] = useState(null);
  const [pictureCvName, setPictureCvName] = useState("");
  const [abstractFile, setAbstractFile] = useState(null);
  const [abstractURL, setAbstractURL] = useState("");
  const [abstractName, setAbstractName] = useState("");
  const [presentationFile, setPresentationFile] = useState(null);
  const [presentationURL, setPresentationURL] = useState("");
  const [presentationName, setPresentationName] = useState("");
  const [industry, setIndustry] = useState("");
  const [role, setRole] = useState("");
  const [error, setError] = useState("");
  const [errorUrl, setErrorUrl] = useState("");
  const [show, setShow] = useState(false);
  const [sMsg, setSMsg] = useState("");
  const [resError, setResError] = useState(false);
  const [imgAlert, setImgAlert] = useState("");
  const [cvImgAlert, setCvImgAlert] = useState("");

  useEffect(() => {
    setPreference([]);
    setError("");
  }, []);

  // const validateURL = (url) => {
  //   const urlPattern =
  //     /^(https?:\/\/)?([\w\d-]+\.)+[\w\d]{2,}(\/[\w\d#-]+)*(\?.*)?(#.*)?$/i;
  //   return urlPattern.test(url);
  // };

  // const handleUrlChange = (e) => {
  //   const value = e.target.value;
  //   setLinkedIn(value);

  //   if (value.length <= 1) {
  //     setError("");
  //   } else if (validateURL(value)) {
  //     setError("");
  //   } else {
  //     setError("Please enter a valid LinkedIn profile URL");
  //   }
  // };

  // const validateURL = (url) => {
  //   const urlPattern = /^https?:\/\/(www\.)?linkedin\.com\/in\/[\w\d-]+\/?$/i;
  //   return urlPattern.test(url);
  // };
  // const validateLinkedInURL = (url) => {
  // const regex =/^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]*)?(\?[^ ]+)?$/;
  // return regex.test(url);
  // };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    setLinkedIn(value);

    if (value.length <= 1) {
      setErrorUrl("");
    } else if (validateLinkedInURL(value)) {
      setErrorUrl("");
    } else {
      setErrorUrl("Please enter a valid LinkedIn profile URL");
    }
  };

  // Function to validate email using a regular expression
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;

    // Allow digits and the + character, ensuring at least 10 digits in total
    if (/^\+?\d*$/.test(value)) {
      setMobile(value);

      // Count digits ignoring the +
      const digitCount = (value.match(/\d/g) || []).length;

      if (digitCount >= 10 || digitCount <= 5) {
        setMobileError(""); // Valid mobile number
      } else {
        setMobileError("Mobile number must contain at least 10 digits");
      }
    } else {
      setMobileError("Invalid mobile number");
    }
  };

  const maxFileSize = 2 * 1024 * 1024;

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxFileSize) {
        setImgAlert(
          "File size exceeds the 2MB limit. Please upload a smaller file."
        );
        return;
      }

      setPicture(file);
      setPictureUrl(URL.createObjectURL(file));
      setPictureName(file.name);
    }
  };

  const handleCvFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxFileSize) {
        setCvImgAlert(
          "File size exceeds the 2MB limit. Please upload a smaller file."
        );
        return;
      }
      setPictureCv(file);
      setPictureCvUrl(URL.createObjectURL(file));
      setPictureCvName(file.name);
    }
  };

  const resetFileInput = () => {
    setImgAlert("");
    setPicture(null);
    setPictureUrl(null);
    setPictureName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const resetCvFileInput = () => {
    setCvImgAlert("");
    setPictureCv(null);
    setPictureCvUrl(null);
    setPictureCvName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const [AOI, setAOI] = useState(4);
  const [preference, setPreference] = useState("");
  const [chips, setChips] = useState([]);

  // Handle adding chip
  const addChip = () => {
    if (preference.trim() !== "") {
      setChips([...chips, preference.trim()]);
      setPreference(""); // Clear input field
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && preference.trim() !== "") {
      e.preventDefault(); // Prevent form submission
      setChips([...chips, preference.trim()]);
      setPreference(""); // Clear input field
    }
  };

  const handleChipRemove = (index) => {
    setChips(chips.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("company", company);
    formData.append("role", role);
    formData.append("industry", industry);
    chips?.forEach((item) => {
      formData.append("preference", item);
    });
    formData.append("areaOfInterest", AOI);
    formData.append("linkedInUrl", linkedIn);
    formData.append("photo", picture);
    formData.append("coverPhoto", pictureCv);
    formData.append("email", email);
    formData.append("phoneNumber", mobile);
    formData.append("abstract", abstractFile);
    formData.append("introduction", intro);
    formData.append("presentation", presentationFile);
    try {
      axiosPostForm
        .post(`speaker/registeration`, formData)
        .then((response) => {
          if (response.data.message === "Speaker created successfully") {
            // window.location.reload();
            setFirstName("");
            setLastName("");
            setCompany("");
            setAOI("");
            setLinkedIn("");
            setRole("");
            setIndustry("");
            setPicture("");
            setIntro("");
            setOpen(false);
            setPictureUrl("");
            setPresentationFile("");
            setPresentationName("");
            setPresentationURL("");
            setAbstractFile("");
            setAbstractName("");
            setAbstractURL("");
            setShow(true);
            setResError(false);
            setSMsg(response.data.message);
            setEmail("");
            setMobile("");
            setPictureCv("");
            setPictureCvName("");
            setPictureCvUrl("");
          } else {
            console.error("Registration failed:", response.data.message);
          }
        })
        .catch((response) => {
          setShow(true);
          setResError(true);
          setSMsg(response.response.data.error);
        });
    } catch (error) {
      console.error("POST Error:", error);
    }
  };
  const [isShaking, setIsShaking] = useState(false);

  const handleShake = () => {
    setIsShaking(true);
    setTimeout(() => {
      setIsShaking(false);
    }, 500);
  };

  const inputClass =
    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-3";

  const handleIntroChange = (e) => {
    const { value } = e.target;
    if (value.length == 3000) {
      setError("Character limit of 3000 reached");
    } else {
      setError("");
    }
    setIntro(value);
  };

  const dialogClose = () => {
    setOpen(false);
    setPictureCvUrl(null);
    setPictureCv(null);
    setPictureUrl(null);
    setPicture(null);
    setPresentationFile(null);
    setAbstractFile(null);
  };

  return (
    <>
      <Dialog open={open} onClose={handleShake} className="relative z-30">
        {/* onClose={handleShake} */}
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <DialogPanel
              transition
              className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:max-w-2xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95  ${
                isShaking ? "animate-shake" : ""
              }`}
            >
              <button
                onClick={dialogClose}
                // className={`absolute top-4 right-4 text-red-600 ${isShaking ? "animate-shake" : ""}`}
                className={`absolute top-4 right-4 text-red-600`}
              >
                <MdClose />
              </button>
              <h2
                className="md:text-2xl text-center py-3 global-title"
                style={{ fontFamily: "custom" }}
              >
                Speaker Registration
              </h2>

              <div className="grid grid-cols-5 md:grid-cols-8 gap-3 w-full mx-w-5xl bg-white mt-2">
                <div className="row-span-3 col-span-2 md:col-span-2 flex justify-center">
                  <div>
                    <h1 className="text-xs pb-1 ">Profile picture</h1>
                    {pictureUrl ? (
                      <div className="relative text-center p-1 rounded-md border-2 border-dashed border-[#ccc] w-[135px] h-[125px] grid place-items-center object-cover">
                        <img
                          className="w-full rounded-xl h-[112px] object-cover"
                          src={pictureUrl}
                          alt={pictureName}
                        />
                        <button
                          className="absolute top-1 right-1 p-1 bg-[#f44336] rounded-full"
                          onClick={resetFileInput}
                        >
                          <CgClose className="text-white w-4" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <label htmlFor="profile-image">
                          <div className=" text-center p-1 rounded-md border-2 border-dashed border-[#ccc] w-[135px] h-[125px] grid place-items-center">
                            <img
                              className="scale-75 w-full"
                              alt="no images"
                              src="/images/upload500.svg"
                            />
                          </div>
                          <input
                            id="profile-image"
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                          />
                        </label>
                        {imgAlert ? (
                          <p className="text-red-500 text-xs text-center pb-2">
                            {imgAlert}
                          </p>
                        ) : (
                          <p className="text-gray-400  text-xs text-center pb-2">
                            Maximum image size is 2MB.
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="row-span-3 col-span-2 md:col-span-2 flex justify-center">
                  <div>
                    <h1 className="text-xs pb-1">Cover picture</h1>
                    {pictureCvUrl ? (
                      <div className="relative text-center p-1 rounded-md border-2 border-dashed border-[#ccc] w-[155px] h-[125px] grid place-items-center object-cover">
                        <img
                          className="w-full rounded-xl h-[112px] object-cover"
                          src={pictureCvUrl}
                          alt={pictureCvName}
                        />
                        <button
                          className="absolute top-1 right-1 p-1 bg-[#f44336] rounded-full"
                          onClick={resetCvFileInput}
                        >
                          <CgClose className="text-white w-4" />
                        </button>
                      </div>
                    ) : (
                      <>
                        <label htmlFor="cover-image">
                          <div className=" text-center p-1 rounded-md border-2 border-dashed border-[#ccc] w-[135px] h-[125px] grid place-items-center">
                            <img
                              className="scale-75 w-full"
                              alt="no images"
                              src="/images/upload1280.svg"
                            />
                          </div>
                          <input
                            id="cover-image"
                            type="file"
                            ref={fileInputRef}
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleCvFileChange}
                          />
                        </label>
                        {cvImgAlert ? (
                          <p className="text-red-500 text-xs text-center pb-2">
                            {cvImgAlert}
                          </p>
                        ) : (
                          <p className="text-gray-400  text-xs text-center pb-2">
                            Maximum image size is 2MB.
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {/* Other Input Fields */}
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="firstName"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    First Name*
                  </label>
                  <input
                    id="firstName"
                    type="text"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    className={inputClass}
                  />
                </div>
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="lastName"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    id="lastName"
                    type="text"
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    className={inputClass}
                  />
                </div>
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="role"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Job Role
                  </label>
                  <input
                    id="role"
                    name="role"
                    type="text"
                    onChange={(e) => setRole(e.target.value)}
                    className={inputClass}
                  />
                  {/* <select
                    id="role"
                    name="role"
                    required
                    onChange={handleRoleChange}
                    value={role._id}
                    className={inputClass}
                  >
                    <option value="" disabled selected>
                      Select a role
                    </option>
                     {roleData ? (
                      roleData.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data.roleName}
                        </option>
                      ))
                    ) : (
                      <option disabled>Loading...</option>
                    )}
                  </select> */}
                </div>
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="company"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Company*
                  </label>
                  <input
                    id="company"
                    type="text"
                    required
                    onChange={(e) => setCompany(e.target.value)}
                    className={inputClass}
                  />
                </div>
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="industry"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Industry Type
                  </label>
                  <input
                    id="industry"
                    name="industry"
                    type="text"
                    onChange={(e) => setIndustry(e.target.value)}
                    className={inputClass}
                  />
                  {/* <select
                    id="industry"
                    name="role"
                    required
                    onChange={handleIndustryChange}
                    value={industry._id}
                    className={inputClass}
                  >
                    <option value="" disabled selected>
                      Select an industry
                    </option>
                   {industryData ? (
                      industryData.map((data) => (
                        <option key={data._id} value={data._id}>
                          {data.industryName}
                        </option>
                      ))
                    ) : (
                      <option disabled>Loading...</option>
                    )}
                  </select> */}
                </div>
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Mobile*
                  </label>
                  <input
                    id="mobile"
                    name="mobile"
                    type="text"
                    required
                    onChange={handleMobileChange}
                    className={inputClass}
                  />
                  {mobileError && (
                    <div className="text-red-500 text-xs px-1.5 p-0.5">
                      {mobileError}
                    </div>
                  )}
                </div>
                <div className="relative col-span-5 md:col-span-4">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Email*
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="text"
                    required
                    onChange={handleEmailChange}
                    className={inputClass}
                  />
                  {emailError && (
                    <div className="text-red-500 text-xs px-1.5 p-0.5">
                      {emailError}
                    </div>
                  )}
                </div>

                <div className="relative col-span-5 md:col-span-8">
                  <label
                    htmlFor="linkedIn"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    LinkedIn URL
                  </label>
                  <input
                    id="linkedIn"
                    type="text"
                    onChange={handleUrlChange}
                    // onChange={(e) => setLinkedIn(e.target.value)}
                    className={inputClass}
                  />
                  {errorUrl && (
                    <div className="text-red-500 text-xs px-1.5 p-0.5">
                      {errorUrl}
                    </div>
                  )}
                </div>
                {/* <div className="relative col-span-5 md:col-span-8">
                  <label
                    htmlFor="aoi"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Area of Interest
                  </label>
                  <input
                    id="aoi"
                    type="text"
                    required
                    onChange={(e) => setAoi(e.target.value)}
                    className={inputClass}
                  />
                </div> */}
                <div className="relative col-span-5 md:col-span-8">
                  <label
                    htmlFor="intro"
                    className={`absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium${
                      error ? "text-red-500" : " text-gray-900 "
                    }`}
                  >
                    Introduction
                  </label>
                  <textarea
                    id="intro"
                    required
                    onChange={handleIntroChange}
                    maxLength={3000}
                    className={`resize-none overflow-y-auto aScrollBar max-h-96 p-2 border border-gray-300 rounded-md ${inputClass}  ${
                      error ? "ring-red-500" : ""
                    }`}
                  />
                  {error ? (
                    <p className="mt-1 text-red-500 text-xs">{error}</p>
                  ) : (
                    <p className="mt-1 text-slate-500 text-xs">
                      Max 3000 character{" "}
                    </p>
                  )}
                  {/* <p className="mt-1 text-gray-400 text-xs">
                    Max length: 1000 characters.
                  </p> */}
                </div>
                {/* <div className="relative col-span-5 md:col-span-3">
                  <label
                    htmlFor="aoi"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Area of Interest
                  </label>
                  <select
                    id="aoi"
                    name="aoi"
                    required
                    value={AOI}
                    onChange={(e) => setAOI(e.target.value)}
                    className={inputClass}
                  >
                     <option value="" disabled selected>
                    Select an industry
                  </option> 
                    <option value={1}>Conditional Monitoring</option>
                    <option value={2}>Reliability</option>
                    <option value={3}>Both</option>

                  
                  </select>
                </div> */}
                <div className="relative col-span-full">
                  <label
                    htmlFor="aoi"
                    className=" inline-block px-1 text-xs font-medium text-gray-900"
                  >
                    Area of Interest
                  </label>
                  <div
                    id="aoi"
                    className="flex flex-col md:flex-row gap-2 pt-1"
                  >
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value={1}
                        checked={AOI === 1}
                        onChange={(e) => setAOI(1)}
                      />
                      <span className="ml-2 text-sm">
                        Conditional Monitoring
                      </span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value={2}
                        checked={AOI === 2}
                        onChange={(e) => setAOI(2)}
                      />
                      <span className="ml-2 text-sm">Reliability</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        value={3}
                        checked={AOI === 3}
                        onChange={(e) => setAOI(3)}
                      />
                      <span className="ml-2 text-sm">Both</span>
                    </label>
                    <label className="flex items-center">
                      <input
                        type="radio"
                        defaultChecked
                        value={4}
                        checked={AOI === 4}
                        onChange={(e) => setAOI(4)}
                      />
                      <span className="ml-2 text-sm">None</span>
                    </label>
                  </div>
                </div>
                {/* <div className="relative col-span-full ">
                  <div className="flex">
                    <label
                      htmlFor="inputField"
                      className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                    >
                      Preference
                    </label>

                    <input
                      id="inputField"
                      name="inputField"
                      type="text"
                      value={preference}
                      onChange={(e) => setPreference(e.target.value)}
                      onKeyDown={handleKeyDown}
                      className={inputClass}
                    />

                    <button
                      type="button"
                      onClick={addChip}
                      className="p-2 text-black rounded-full absolute right-0 "
                    >
                      <PlusIcon className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="text-blue-500 text-xs px-1.5 py-2 col-span-full">
                    Press enter to add
                  </div>

                  <div className="flex flex-wrap w-full gap-2 col-span-full max-w-[620px]">
                    {chips.map((chip, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-gray-200 rounded px-2 py-1 text-xs text-gray-700"
                      >
                        {chip}
                        <button
                          type="button"
                          onClick={() => handleChipRemove(index)}
                          className="ml-2 text-red-500"
                        >
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div> */}
                <div className="relative col-span-full">
                  <label
                    htmlFor="inputField"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Preference
                  </label>

                  <div className="flex flex-wrap items-center border rounded-md px-1 bg-white">
                    <input
                      id="inputField"
                      name="inputField"
                      type="text"
                      value={preference}
                      onChange={(e) => setPreference(e.target.value)}
                      onKeyDown={handleKeyDown}
                      className={` flex-1 border-none outline-none`}
                      // placeholder="Add a preference"
                    />
                    {/* Render chips inside the input field */}
                    {chips.map((chip, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-gray-200 rounded px-2 py-1 text-xs text-gray-700 mr-1"
                      >
                        {chip}
                        <button
                          type="button"
                          onClick={() => handleChipRemove(index)}
                          className="ml-2 text-red-500"
                        >
                          &times;
                        </button>
                      </div>
                    ))}

                    <button
                      type="button"
                      onClick={addChip}
                      className="p-2 text-black rounded-full ml-1"
                    >
                      <PlusIcon className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="text-slate-400 text-xs px-1.5 py-2 col-span-full">
                    Press enter to add
                  </div>
                </div>
                {/* File Uploads */}
                <FileUpload
                  file={abstractFile}
                  setFile={setAbstractFile}
                  fileUrl={abstractURL}
                  setFileUrl={setAbstractURL}
                  fileName={abstractName}
                  setFileName={setAbstractName}
                  label="Abstract"
                  fileType="abstract"
                />
                <FileUpload
                  file={presentationFile}
                  setFile={setPresentationFile}
                  fileUrl={presentationURL}
                  setFileUrl={setPresentationURL}
                  fileName={presentationName}
                  setFileName={setPresentationName}
                  label="Presentation"
                  fileType="presentation"
                />
              </div>

              <div className="flex w-full justify-center">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="text-white bg-[#FF6500] hover:bg-[#000] focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 my-2 duration-200 w-full md:w-1/3"
                >
                  Register
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <Toast msg={sMsg} show={show} setShow={setShow} error={resError} />
    </>
  );
}
